import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { GeneralProposalEntity } from "domain/entity/GeneralProposal/GeneralProposalEntity";
import { GeneralProposalSearchCriteria } from "domain/entity/GeneralProposal/GeneralProposalSearchCriteria";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { GeneralProposalRepository } from "./GeneralProposalRepo";

export const GeneralProposalRepoImpl = (): GeneralProposalRepository => {
    
    const searchUrl = "/v1/generalProposalSearch"
    const deleteUrl = "/v1/generalProposalDelete"
    const submitUrl = "/v1/generalProposalSubmit"
    const unconfirmUrl = "/v1/generalProposalUnconfirm"
    const rejectUrl = "/v1/generalProposalReject"
    const approveUrl = "/v1/generalProposalApprove"
    const renewUrl = "/v1/generalProposalRenew"
    const saveItemUrl = '/v1/generalProposalSaveProposalItemDetail'
    const saveUrl = '/v1/generalProposalSave'
    const searchGeneralProposal = async(searchCriteria: GeneralProposalSearchCriteria) : Promise<GeneralProposalEntity[]> => {
        
        return axiosPostData(tariffAxiosInstance,`${searchUrl}`, searchCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const deleteGNProposal = async(keys: string[]) : Promise<string> => {
        
        return axiosPostData(tariffAxiosInstance,`${deleteUrl}`, keys).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const submitGeneralProposal = async(selectedRows: GeneralProposalEntity[]) : Promise<string> => {
        
        return axiosPostData(tariffAxiosInstance,`${submitUrl}`, selectedRows).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const unconfirmGeneralProposal = async(selectedRows: GeneralProposalEntity[]) : Promise<string> => {
        
        return axiosPostData(tariffAxiosInstance,`${unconfirmUrl}`, selectedRows).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const approveGeneralProposal = async(selectedRows: GeneralProposalEntity[]) : Promise<string> => {
        
        return axiosPostData(tariffAxiosInstance,`${approveUrl}`, selectedRows).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const onRejectGeneralProposal = async(selectedRow: GeneralProposalEntity) : Promise<string> => {
        
        return axiosPostData(tariffAxiosInstance,`${rejectUrl}`, selectedRow).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const renewGeneralProposal = async(keys: number[]) : Promise<string> => {        
        return axiosPostData(tariffAxiosInstance,`${renewUrl}`, keys).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }


    const saveProposalItemDetail = async(gnPropsal: GeneralProposalEntity) : Promise<ResponseEntity> => {
        return axiosPostData(tariffAxiosInstance,`${saveItemUrl}`, gnPropsal)
    }
    const saveGeneralProposal = async(gnPropsal: GeneralProposalEntity) : Promise<ResponseEntity> => {
        return axiosPostData(tariffAxiosInstance,`${saveUrl}`, gnPropsal)
    }

    return {
        saveGeneralProposal : saveGeneralProposal,
        searchGeneralProposal : searchGeneralProposal,
        deleteGNProposal : deleteGNProposal,
        submitGeneralProposal : submitGeneralProposal,
        unconfirmGeneralProposal : unconfirmGeneralProposal,
        onRejectGeneralProposal : onRejectGeneralProposal,
        approveGeneralProposal : approveGeneralProposal,
        renewGeneralProposal: renewGeneralProposal,
        saveProposalItemDetail: saveProposalItemDetail,
    }
}
