import { EMPTY_GENERAL_PROPOSAL_ENTITY, GeneralProposalEntity } from "domain/entity/GeneralProposal/GeneralProposalEntity";
import { EMPTY_GEN_PROPOSAL_IS_ENTITY, GeneralProposalIsEntity } from "domain/entity/GeneralProposal/GeneralProposalIsEntity";
import { EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY, GenProposalTariffItemTierEntity } from "domain/entity/GeneralProposal/GeneralProposalTariffItemTierEntity";
import { ShiftRepository } from "domain/repository/Common/ShiftRepo";
import { ExchangeRateRepository } from "domain/repository/ExchangeRate/ExchangeRateRepo";
import { GeneralProposalRepository } from "domain/repository/GeneralProposal/GeneralProposalRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";
import { GeneralProposalDetailModel } from "presentation/model/GeneralProposal/GeneralProposalDetailModel";
import { GeneralProposalModel } from "presentation/model/GeneralProposal/GeneralProposalModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface GeneralProposalDetailVMProps extends BaseViewModel {
    itemDispatch: [
        Dispatch<SetStateAction<GeneralProposalDetailModel>> | ((value: SetStateAction<GeneralProposalDetailModel>) => void),
    ],
    dispatch: [
        Dispatch<SetStateAction<GeneralProposalModel>> | ((value: SetStateAction<GeneralProposalModel>) => void),
    ],
    generalProposalRepo: GeneralProposalRepository,
    exchangeRateRepo: ExchangeRateRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    shiftRepo: ShiftRepository
}

export const GeneralProposalDetailVM = ({ itemDispatch,dispatch, generalProposalRepo, exchangeRateRepo, standardTariffCodeRepo,shiftRepo }: GeneralProposalDetailVMProps) => {
    const [generalProposalDetailDispatch] = itemDispatch;
    const [generalProposalDispatch] = dispatch;

    const loadDropdownOption = async () => {

        await exchangeRateRepo.getAllCurrencies().then(
            currencies => {
                let currencyDropdownOptions = currencies?.map((currency) => ({
                    dropdownLabel: currency.currencyCode,
                    tagLabel: currency.currencyCode,
                    value: currency.currencyCode,
                })) ?? []

                generalProposalDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        currencyCodeDropdownOptions: currencyDropdownOptions,
                    }
                }))
            }
        )

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {
                
                let newTariffs = _.orderBy(tariffs, ["tariffType","tariffCode"]);                
                let tariffTypeDropdownOptions:DropdownProps[] = [];
                let tariffCodeDropdownOptions:{[key:string]: DropdownProps[]} = {};

                newTariffs.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t => 
                        t.value === tariff.tariffType);
                    if(!isTariffTypeExisted){
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    if(tariff.tariffCode){
                        if(!tariffCodeDropdownOptions[tariff.tariffType]){
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }
                })            

                generalProposalDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )

        await shiftRepo.getShiftByType('INTENSIVE').then(
            shiftCodes => {

                let newShifts = _.orderBy(shiftCodes, ["shiftCode"]); 
                let shiftCodeDropdownOptions:DropdownProps[] = [];
                newShifts.forEach(shift => {
                    const isShiftCodeExisted = shiftCodeDropdownOptions.find(t => 
                        t.value === shift.shiftCode);
                    if(!isShiftCodeExisted){
                        shiftCodeDropdownOptions.push({
                            dropdownLabel: shift.shiftCode,
                            tagLabel: shift.shiftCode,
                            value: shift.shiftCode,
                        })
                    }
                })   

                generalProposalDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        shiftCodeDropdownOptions: shiftCodeDropdownOptions,
                    }
                }))
            }
        )
    }

    const onPageInit = () => {
        generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentGeneralProposal: { ...EMPTY_GENERAL_PROPOSAL_ENTITY },
                detailState: {
                    ...prevState.detailState,
                    isAdd: true,
                }
            }
        })
    }

    const onHeaderSingleDropdownChange = (e: any, fieldName: string) => {
        generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentGeneralProposal: {
                    ...prevState.currentGeneralProposal,
                    [fieldName]: e.value,
                }
            }
        });
    };

    const onHeaderMultipleDropdownChange = (e: any, fieldName: string) => {
        generalProposalDetailDispatch(prevState => ({
            ...prevState,
            currentGeneralProposal: {
                ...prevState.currentGeneralProposal,
                [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
            }
        }));
    };

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentGeneralProposal: {
                    ...prevState.currentGeneralProposal,
                    [fieldKey]: val
                },
            }
        })
    }

    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        generalProposalDetailDispatch(prevState => ({
            ...prevState,
            currentGeneralProposal: {
                ...prevState.currentGeneralProposal,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onTextAreaChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        return generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentGeneralProposal: {
                    ...prevState.currentGeneralProposal,
                    [fieldKey]: val,
                }
            }
        });
    }

    const updateSelectedRows = async (rows: any[]) => {
        generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentSelectItem: rows[0],
            }
        })
    }

    const onResetClick = async () => {
        generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                generalProposalItemList: [],
                currentGeneralProposal: {...EMPTY_GENERAL_PROPOSAL_ENTITY},
            }
        })
    }

    const onCloseClick = () => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackFromDetail:true,
                isAllowAutoSearch:true,
                selectedRows:[],
                currentSelectedRow: {...EMPTY_GENERAL_PROPOSAL_ENTITY},
            }
        }); 
    }

    const onTierFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        generalProposalDetailDispatch(prevState => {
            if(prevState.currentEditTariffItemTier && (fieldKey==='cycle'||fieldKey==='forevery')){
                var posIntReg = /^\d*$/;
                if (!posIntReg.test(val)) { 
                    val = prevState.currentEditTariffItemTier[fieldKey];
                }
            }
            return {
                ...prevState,
                currentEditTariffItemTier: {
                    ...prevState.currentEditTariffItemTier,
                    [fieldKey]: val
                },
            }
        })
    }

    const onIsFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentEditTariffItemIs: {
                    ...prevState.currentEditTariffItemIs,
                    [fieldKey]: val
                },
            }
        })
    }
    
    const updateSelectedTariffItemTierRows = async (rows:any[]) => {
        generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentTariffItemTier: rows[0],
                tariffItemTierSelectedRows: rows,
            }
        })
    }

    const updateSelectedTariffItemIsRows = async (rows:any[]) => {
        generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentTariffItemIs: rows[0],
                tariffItemIsSelectedRows: rows,
            }
        })
    }

    const onTariffItemTierCloseClick = () => {
        generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowTariffItemTierPanel: false,
                gnProposalState:{
                    ...prevState.gnProposalState,
                    isRead: true,
                },
                isEditTier:false,
                isEditIs:false,
                isAddIs:false,
                isAddTier:false,                
                tariffItemTierSelectedRows: [],
                tariffItemIsSelectedRows: [],     
            }
        })
    }


    const onTariffItemTierEidtClick = () => {
        generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isEditTier:true,
                isEditIs:false,
                isAddIs:false,
                isAddTier:false,
                currentEditTariffItemTier: {
                    ...prevState.currentTariffItemTier,
                },
                gnProposalState:{
                    ...prevState.gnProposalState,
                    isRead: false,
                }
            }
        })
    }

    const onTariffItemISEidtClick = () => {
        generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isEditIs:true,
                isEditTier:false,
                isAddIs:false,
                isAddTier:false,
                currentEditTariffItemIs:prevState.currentTariffItemIs??EMPTY_GEN_PROPOSAL_IS_ENTITY,
                gnProposalState:{
                    ...prevState.gnProposalState,
                    isRead: false,
                }
            }
        })
    }

    const cancelAddOrEdit = () => {
        return generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isAddIs: false,
                isEditIs: false,
                isAddTier: false,
                isEditTier: false,
                // 
                currentTariffItemTier: prevState.currentTariffItemTier,
                currentTariffItemIs: prevState.currentTariffItemIs
            }
        });
    }

    
    const onAddIs = () => {
        return generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isAddIs: true,
                isEditIs: false,
                isAddTier: false,
                isEditTier: false,
                currentEditTariffItemIs: {...EMPTY_GEN_PROPOSAL_IS_ENTITY,
                    tempKey: _.uniqueId(),
                }
            }
        });
    }

    const onAddTier = () => {
        return generalProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isAddIs: false,
                isEditIs: false,
                isAddTier: true,
                isEditTier: false,
                currentEditTariffItemTier: {
                    ...EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY,
                    tierNo: _.isEmpty(prevState.generalProposalItemTierList)? 1 : prevState.generalProposalItemTierList.length + 1,
                    tempKey: _.uniqueId(),
                }
            }
        });
    }

    const onTempSaveClick = (fieldName: string, currentEditing: GenProposalTariffItemTierEntity | GeneralProposalIsEntity | null) => {
        if(currentEditing === null) return;
        return generalProposalDetailDispatch(prevState => {
                const origList = prevState[fieldName]??[];
                let newList = origList.filter((e: { tempKey: string; }) => (e.tempKey !== currentEditing.tempKey));
                newList.push(currentEditing);
                return {
                    ...prevState,      
                    [fieldName]: _.sortBy(newList,"tierNo"),
                    isAddIs: false,
                    isEditIs: false,
                    isAddTier: false,
                    isEditTier: false,
                    currentTariffItemTier: { ...EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },   
                    currentTariffItemIs: { ...EMPTY_GEN_PROPOSAL_IS_ENTITY },
                    currentEditTariffItemIs: { ...EMPTY_GEN_PROPOSAL_IS_ENTITY },
                    currentEditTariffItemTier: { ...EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },        
                    tariffItemTierSelectedRows: [],
                    tariffItemIsSelectedRows: [],     
                }
        });
    }

    const onSaveAll = async (gnPropsal: GeneralProposalEntity, generalProposalItemTierList: GenProposalTariffItemTierEntity[], generalProposalItemIsList: GeneralProposalIsEntity[]) =>  {
        return await generalProposalRepo.saveProposalItemDetail({
            ...gnPropsal,
            tarItemTierList: generalProposalItemTierList,
            tarItemIsList: generalProposalItemIsList,
        }).then((res) =>{

            generalProposalDetailDispatch(prevState => {
                return {
                    ...prevState,
                    isAddIs: false,
                    isEditIs: false,
                    isAddTier: false,
                    isEditTier: false,
                    currentTariffItemTier: { ...EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },   
                    currentTariffItemIs: { ...EMPTY_GEN_PROPOSAL_IS_ENTITY },
                    currentEditTariffItemIs: { ...EMPTY_GEN_PROPOSAL_IS_ENTITY },
                    currentEditTariffItemTier: { ...EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },  
                    tariffItemTierSelectedRows: [],
                    tariffItemIsSelectedRows: [],     
                    gnProposalState:{
                        ...prevState.gnProposalState,
                        isRead: false,
                    }
                }
            });

            return res;
            
        })
    }

    
    const onDeleteTier = async () => {
        return generalProposalDetailDispatch(prevState => {
            const delTierKeys = prevState.tariffItemTierSelectedRows.map(t => t.tempKey);

            const retainTierList = prevState.generalProposalItemTierList
                .filter(t => !delTierKeys.includes(t.tempKey))
                .map((t,i) => ({...t,tierNo:i+1}))

            return {
                ...prevState,
                generalProposalItemTierList: retainTierList,
                isEditingProposal: true,
            }
        });
    }

    const onDeleteIs = async () => {
        return generalProposalDetailDispatch(prevState => {
            const delIsKeys = prevState.tariffItemIsSelectedRows.map(t => t.tempKey);

            const retainIsList = prevState.generalProposalItemIsList
                .filter(t => !delIsKeys.includes(t.tempKey))

            return {
                ...prevState,
                deleteIsList: prevState.tariffItemIsSelectedRows.filter(t=>!t.newRecord),
                generalProposalItemIsList: retainIsList,
                isEditingProposal: true,
            }
        });
    }
    

    return {
        onPageInit: onPageInit,
        loadDropdownOption: loadDropdownOption,
        onHeaderFieldChange: onHeaderFieldChange,
        onDateRangeChange: onDateRangeChange,
        onTextAreaChange: onTextAreaChange,
        updateSelectedRows: updateSelectedRows,
        onCloseClick: onCloseClick,
        onResetClick: onResetClick,
        onHeaderMultipleDropdownChange: onHeaderMultipleDropdownChange,
        onHeaderSingleDropdownChange: onHeaderSingleDropdownChange,
        onTierFieldChange: onTierFieldChange,
        onIsFieldChange: onIsFieldChange,
        updateSelectedTariffItemTierRows: updateSelectedTariffItemTierRows,
        updateSelectedTariffItemIsRows: updateSelectedTariffItemIsRows,
        onTariffItemTierCloseClick: onTariffItemTierCloseClick,
        onTariffItemTierEidtClick: onTariffItemTierEidtClick,
        onTariffItemISEidtClick: onTariffItemISEidtClick,
        onSaveAll: onSaveAll,
        cancelAddOrEdit: cancelAddOrEdit,
        onAddIs: onAddIs,
        onAddTier: onAddTier,
        onTempSaveClick: onTempSaveClick,
        onDeleteTier: onDeleteTier,
        onDeleteIs: onDeleteIs,
    }
}