import { useGeneralProposalDetailVM } from "presentation/hook/GeneralProposal/useGeneralProposalDetailVM";
import { memo, useCallback, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import GeneralProposalDetailTitleBar from "./GeneralProposalDetailTitleBar";
import { GeneralProposalHeaderFormPanel } from "./Left/GeneralProposalHeaderFormPanel";
import GeneralProposalDetailTablePanel from "./Table/GeneralProposalDetailTablePanel";

const GeneralProposalDetailMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const generalProposalDtlVM = useGeneralProposalDetailVM();


    const initialScreen = useCallback(async () => {
        setIsLoading(true);
        try {
            const results = await Promise.allSettled([
                generalProposalDtlVM.onPageInit(),
                generalProposalDtlVM.loadDropdownOption(),
            ]);
            results.forEach((result, index) => {
                if (index === 0 && result.status === 'fulfilled') {

                } else if (index === 1 && result.status === 'rejected') {

                }
            });
            setIsLoading(false);
        } catch (error) {
        }
    }, [generalProposalDtlVM]);

    useEffect(() => {
        initialScreen();
    }, [initialScreen])


    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <div className="main-comp-wrapper">
            <GeneralProposalDetailTitleBar />
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={"25%"}
                rightSectionWidth={"75%"}
                leftChildren={<GeneralProposalHeaderFormPanel />}
                rightChildren={<GeneralProposalDetailTablePanel />}
            />
        </div>
    </>
}

export default memo(GeneralProposalDetailMaintenance);