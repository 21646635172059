import { SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_IS_COL_DEF, transferRowData } from "presentation/constant/GeneralProposal/GeneralProposalTariffItemISColumnDefinition";
import { useGeneralProposalDetailVM } from "presentation/hook/GeneralProposal/useGeneralProposalDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useGeneralProposalDetailTracked } from "presentation/store/GeneralProposal/GeneralProposalDetailProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const AddTariffItemIsTablePanel: React.FC = () => {
    const [generalProposalDetailState] = useGeneralProposalDetailTracked();
    const generalProposalDetailVM = useGeneralProposalDetailVM();
    const { generalProposalItemIsList, generalProposalItemTierList,currentTariffItemIs, isAddIs,isEditIs} = generalProposalDetailState;
    let gridRef: any = useRef();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const messageBarVM = useMessageBarVM();

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);          
        const selectedRows = e.api.getSelectedRows();
        generalProposalDetailVM.updateSelectedTariffItemIsRows(selectedRows);
    }, [generalProposalDetailVM])


    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_IS_COL_DEF.slice());
        
        // if (!generalProposalDetailState.tariffItemIsSelectedRows ||
        //     generalProposalDetailState.tariffItemIsSelectedRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        // }
    })

    const handleAdd = useCallback(() => {
        if(!_.isEmpty(generalProposalItemTierList) && generalProposalItemTierList.length > 1){
            messageBarVM.showError("Intensive surcharge does not support multiple charge tier");
            return;
        }

        generalProposalDetailVM.onAddIs();
    }, [ generalProposalItemTierList, generalProposalDetailVM, messageBarVM]);

    const handleEdit = useCallback(() => {
        if(!currentTariffItemIs){
            messageBarVM.showError("Please select a IS record.");
            return;
        }
        generalProposalDetailVM.onTariffItemISEidtClick();
    }, [currentTariffItemIs, generalProposalDetailVM, messageBarVM]);

    const memoBtns = useMemo(()=>{

        const hdrBtns = [];
        if(!(isAddIs&&!isEditIs)){
            hdrBtns.push({                
                id: 'onEditButton',
                icon: 'Icon-pen',
                title: 'Edit'
            })
        }

        if(!(isAddIs || isEditIs)){
            hdrBtns.push({                
                id: 'onDeleteButton',
                icon: 'Icon-trash',
                title: 'Delete'
            })
        }
        
        return hdrBtns
        
    },[isAddIs, isEditIs])  

    const handleDelete =  useCallback(() => {
        
        generalProposalDetailVM.onDeleteIs();
    },[generalProposalDetailVM])

    const memoGeneralProposalTariffItemTierTable = useMemo(() => {

        return (
            <HPHTable
                id='general-proposal-detail-table'
                isNewColumnSetting={true}
                columns={INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_IS_COL_DEF.slice()}
                data={transferRowData(generalProposalItemIsList ?? [])}
                headerActionButtons={memoBtns}
                onEditButton={handleEdit}
                // onCloseButton={handClose}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAdd}               
                onDeleteButton={handleDelete}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                showUploadIcon={false}
                selectionMode={false}
                rowSelection={"multiple"}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 750px)"
                ref={gridRef}
            />
        );
    }, [generalProposalItemIsList, handleEdit, handleAdd, handleDelete, handleSelectionChange, memoBtns])

    return <><TableWrapper>
        {memoGeneralProposalTariffItemTierTable}
    </TableWrapper>
    </>;
}

export default memo(AddTariffItemIsTablePanel);
