import { GeneralProposalConstant } from "presentation/constant/GeneralProposal/GeneralProposalConstant";
import { useGeneralProposalDetailVM } from "presentation/hook/GeneralProposal/useGeneralProposalDetailVM";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo } from "react";
import { HPHBreadcrumb } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const GeneralProposalDetailTitleBar: React.FC = () => {
    const generalProposalDtlVM = useGeneralProposalDetailVM();

    return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
        <HeaderWithBackButton callback={generalProposalDtlVM.onCloseClick}>
        <Breadcrumb>
        <HPHBreadcrumb breadcrumbData={[{title: GeneralProposalConstant.Agreement.AGREEMENT},{ title: GeneralProposalConstant.Title.GENERAL_PROPOSAL }]} onCurrentClick={()=>{}}></HPHBreadcrumb>
        </Breadcrumb>
        </HeaderWithBackButton>
    </Sidebarheader>
}

export default memo(GeneralProposalDetailTitleBar);