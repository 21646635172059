import { EMPTY_GENERAL_PROPOSAL_ENTITY, GeneralProposalEntity } from "domain/entity/GeneralProposal/GeneralProposalEntity";
import { GeneralProposalIsEntity } from "domain/entity/GeneralProposal/GeneralProposalIsEntity";
import { EMPTY_GENERAL_PROPOSAL_ITEM_ENTITY } from "domain/entity/GeneralProposal/GeneralProposalItemEntity";
import { GenProposalTariffItemTierEntity } from "domain/entity/GeneralProposal/GeneralProposalTariffItemTierEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface GeneralProposalDetailDropdownOptions {
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    currencyCodeDropdownOptions: DropdownProps[],
    shiftCodeDropdownOptions: DropdownProps[],
}
export interface GeneralProposalDetailChangeState extends BaseViewChangeSate {
}

export interface GeneralProposalDetailModel {
    isLoading: boolean,
    dynamicOptions: GeneralProposalDetailDropdownOptions,
    currentGeneralProposal: GeneralProposalEntity,
    generalProposalItemTierList: GenProposalTariffItemTierEntity[],
    generalProposalItemIsList: GeneralProposalIsEntity[],
    tariffItemTierSelectedRows: GenProposalTariffItemTierEntity[],
    tariffItemIsSelectedRows: GeneralProposalIsEntity[],
    currentTariffItemTier: GenProposalTariffItemTierEntity | null,
    currentEditTariffItemTier: GenProposalTariffItemTierEntity | null,
    currentTariffItemIs: GeneralProposalIsEntity | null,
    currentEditTariffItemIs: GeneralProposalIsEntity | null,
    isAddTier: boolean,
    isEditTier: boolean,
    isAddIs: boolean,
    isEditIs: boolean,

    [key: string]: any
}

export const EMPTY_GENERAL_PROPOSAL_DETAIL_MODEL: GeneralProposalDetailModel = {
    isLoading: false,
    currentGeneralProposal: { ...EMPTY_GENERAL_PROPOSAL_ENTITY },
    dynamicOptions: {
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        currencyCodeDropdownOptions: [],
        shiftCodeDropdownOptions: []
    },
    generalProposalItemList: [],
    currentSelectItem: { ...EMPTY_GENERAL_PROPOSAL_ITEM_ENTITY },
    generalProposalItemTierList: [],
    generalProposalItemIsList: [],
    tariffItemTierSelectedRows: [],
    tariffItemIsSelectedRows: [],
    currentTariffItemTier: null,
    currentEditTariffItemTier: null,
    currentTariffItemIs: null,
    currentEditTariffItemIs: null,
    isAddTier: false,
    isEditTier: false,
    isAddIs: false,
    isEditIs: false,
}