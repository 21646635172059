import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { GeneralProposalConstant } from "./GeneralProposalConstant";

const GENERAL_PROPOSAL_CONSTANT = GeneralProposalConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_IS_COL_DEF: any[] = [
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.SHIFT_CODE,
        field: 'shiftCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: false,
        width: 180,
    },
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.SURCHARGE_P,
        field: 'percentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },

].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: { [key: string]: string }, fieldName: string) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
